<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                href="#"
                :disabled="item.disabled"
            >
                {{ item.text }}
            </v-breadcrumbs-item>
        </template>
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
                        <v-card-title>Create Car Park Rate</v-card-title>
                        <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('createfinal')" lazy-validation>
                            <v-container class="fill-height"
                            fluid>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-autocomplete
                                        dense
                                        outlined
                                        label="System"
                                        v-model="form.system.selected"
                                        @change="execFilter('system')"
                                        :items="form.system.items"
                                        item-text="name"
                                        item-value="SystemID"
                                        :rules="form.system.rule"
                                        :loading="form.system.loader"
                                        hide-details="auto"
                                        background-color="white"
                                        disabled
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-autocomplete
                                        dense
                                        outlined
                                        label="Operator"
                                        v-model="form.operator.selected"
                                        @change="execFilter('operator')"
                                        :items="form.operator.items"
                                        item-text="name"
                                        item-value="OperatorID"
                                        :rules="form.operator.rule"
                                        :loading="form.operator.loader"
                                        hide-details="auto"
                                        background-color="white"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-autocomplete
                                        dense
                                        outlined
                                        label="Car Park *"
                                        v-model="form.carpark.selected"
                                        :items="form.carpark.items"
                                        item-text="name"
                                        item-value="CarParkID"
                                        :rules="form.carpark.rule"
                                        :loading="form.carpark.loader"
                                        hide-details="auto"
                                        background-color="white"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form.ratecardname.name"
                                            :rules="form.ratecardname.rule"
                                            label="Rate Card Name *"
                                            required
                                            hide-details='auto'
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form.entrygrace.name"
                                            :rules="form.entrygrace.rule"
                                            label="Entry Grace Period (minutes) *"
                                            required
                                            hide-details='auto'
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form.exitgrace.name"
                                            :rules="form.exitgrace.rule"
                                            label="Exit Grace Period (minutes) *"
                                            required
                                            hide-details='auto'
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <div v-bind:class="validateCutoffMain"
                                            class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                            <div class="v-input__control">
                                                <div class="v-input__slot white">
                                                    <fieldset aria-hidden="true"><legend :style="focuscutoff || form.cutofftime.item ? {'width': '110px'} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                                                    <div class="v-text-field__slot">
                                                    <label for="input-starthour" v-bind:class="validateCutoff" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Cut Off Time (HH:MM) *</label>
                                                    <vue-timepicker placeholder=" " style="padding: none" id="input-starthour" input-class="vtimepicker" @focus="focuscutoff = true"
                                                                    @blur="focuscutoff = false" v-model="form.cutofftime.item" format="h:mm a" manual-input>
                                                        <template v-slot:clearButton>
                                                            <v-icon x-small>fa fa-times</v-icon>
                                                        </template>
                                                    </vue-timepicker>
                                                    </div>
                                                </div>
                                                <div class="v-text-field__details" v-if="form.cutofftime.item == '' && form.cutofftime.rule.length > 0">
                                                    <v-input
                                                        error
                                                        readonly
                                                        v-model="form.cutofftime.item"
                                                        :rules="form.cutofftime.rule"
                                                        height="0"
                                                        class="hide-input-text"
                                                        hide-details="auto"
                                                    >
                                                    </v-input>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <span class="text-body-2">Status</span>
                                        <v-switch
                                        color="success"
                                        class="col-1 pl-0"
                                        v-model="form.activestatus"
                                        :label="`${form.activestatus ? 'Enabled' : 'Disabled'}`"
                                        :disabled="readonly"
                                        hide-details="auto"
                                        ></v-switch>
                                    </v-col>

                                    <v-col cols="12" md="12">
                                        <v-divider class="mb-4"></v-divider>
                                        <span class="text-body-2">Rate Setting</span>
                                        <v-data-table
                                            calculate-widths
                                            :headers="headers"
                                            :items.sync="form.days"
                                            hide-default-footer
                                            :loading="tableloading"
                                            loading-text= "Loading Data... Please wait"
                                            :items-per-page="100"
                                            id="main-table"
                                            disable-sort
                                            hide-default-header
                                            dense
                                            :key="keys"
                                            class="mt-2"
                                        >
                                            <template slot="item" slot-scope="props">
                                                <tr>
                                                    <td>{{props.item.name}}</td>
                                                    <td v-for="(item, index) of [1,2,3,4,5,6,7]" :key="index">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                            <span>
                                                                <span v-if="index - 1 != -1 && props.item.items[index - 1].start_hour == undefined">{{`Rate ${index + 1}`}}</span>
                                                                <span v-else>
                                                                    <span v-on="on" v-if="props.item.items[index].start_hour != undefined"><a id="custom-hover" @click.prevent="viewRate({row: props.item.items[index].dayslotid, row2: props.item.items[index], day: props.item.name, localid: props.index, columnid: index + 1})">{{`${props.item.items[index].start_hour_disp} - ${props.item.items[index].end_hour_disp}`}}</a> <!-- <a id="custom-hover" @click.prevent="deleteSlot(props.index, index)"><v-icon color="red" right x-small>fa fa-trash</v-icon></a> --></span>
                                                                    <a v-else v-on="on" id="custom-hover" @click.prevent="viewRate({row: props.item.items[index].dayslotid, day: props.item.name, localid: props.index, columnid: index + 1})">{{`Rate ${index + 1}`}}</a>
                                                                </span>
                                                                <!-- <a v-else v-on="on" id="custom-hover" @click.prevent="viewRate({day: props.item.name, localid: props.index, columnid: index + 1})">{{`Rate ${index + 1}`}}</a> -->
                                                            </span>
                                                            </template>
                                                            <span>Add Rate</span>
                                                        </v-tooltip>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                        <v-input
                                            error
                                            readonly
                                            v-model="form.rateslots.selected"
                                            :rules="form.rateslots.rule"
                                            height="0"
                                            class="hide-input-text"
                                        >
                                        </v-input>
                                    <!--
                                    <v-expansion-panels
                                    v-model="panel"
                                    multiple
                                    >
                                    <v-expansion-panel
                                        v-for="(item,i) in form.days"
                                        :key="i"
                                    >
                                        <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col cols="12" md="3" v-for="(child, i) in item.items" :key="i">
                                                    <v-card @click.prevent="viewRate(child.dayslotid, child, child)" flat outlined style="cursor: pointer" class="grow">
                                                        <v-card-title>Slot ID {{child.dayslotid}}</v-card-title>
                                                        <v-card-subtitle>Time Period: {{child.start_hour}} - {{child.end_hour}}</v-card-subtitle>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    </v-expansion-panels>
                                    -->
                                    </v-col>
                                    
                                    <v-card-actions>
                                        <v-btn class="mt-4" tile small color="primary" type="submit" :loading="btn_loading"><v-icon left x-small>fa fa-save</v-icon> Save</v-btn>
                                        <v-btn class="mt-4" tile small color="error" type="button" @click="goBack"><v-icon left x-small>fa fa-ban</v-icon> Cancel</v-btn>
                                    </v-card-actions>
                                </v-row>
                            </v-container>
                        </v-form>
            <v-overlay
            :absolute="absolute"
            :value="overlay"
            >
                <v-progress-circular
                indeterminate
                color="primary"
                ></v-progress-circular>
            </v-overlay>
        </v-card>
        <v-snackbar
            v-model="swal.notification"
            top
            :color="swal.scolor"
            >
            {{ swal.message }}
        </v-snackbar>
        <alertbox :dialog="ma.alertmodal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
        <alertdelete :dialog="ma.modal_delete" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :item="ma.item" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></alertdelete>
        <add :dialog="ma.modal_add" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></add>
    </div>
</template>

<script>
import timer from '../../assets/js/sleeper.js';
import {ERR} from '../../assets/js/errhandle.js';
import create from '.././modals/AddRate';
import add from '.././modals/AddRate2';
import alertbox from '../modals/Alert';
import alertdelete from '../modals/AlertDeleteProfile';
import TextEditor from '../TextEditor';
import * as moment from "moment";
import * as tz from "moment-timezone";
import * as AppStorage from '../../assets/js/storage';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
    name: 'Create Car Park Rate',
    title: 'Create Car Park Rate',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park Rate',
                disabled: true,
                href: '#',
                },

                {
                text: 'Create Car Park Rate',
                disabled: true,
                href: '#',
                }
            ],
            items: {},
            search: '',
            tableloading: false,
            headers: [
                { text: 'Days', value: '1', width: '15%' },
                { text: 'Rate 1', value: 'r1', width: '12%' },
                { text: 'Rate 2', value: 'r2', width: '12%' },
                { text: 'Rate 3', value: 'r3', width: '12%' },
                { text: 'Rate 4', value: 'r4', width: '12%' },
                { text: 'Rate 5', value: 'r5', width: '12%' },
                { text: 'Rate 6', value: 'r6', width: '12%' },
                { text: 'Rate 7', value: 'r7', width: '12%' },
            ],
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                alertmodal: false,

                modal_edit: false,
                modal_add: false,
                modal_delete: false,
            },
            modalInfo: [],
            form: {
                rateslots: {selected: '', rule: []},
                ratecardname: {name: '', rule: []},
                cutofftime: {item: '', rule: []},
                entrygrace: {name: '', rule: []},
                exitgrace: {name: '', rule: []},
                createddatetime: '',
                updateddatetime: '',
                activestatus: '',
                days: [
                    {name: 'Monday', items: []},
                    {name: 'Tuesday', items: []},
                    {name: 'Wednesday', items: []},
                    {name: 'Thursday', items: []},
                    {name: 'Friday', items: []},
                    {name: 'Saturday', items: []},
                    {name: 'Sunday', items: []},
                    {name: 'Public Holiday', items: []},
                ],
                system: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                operator: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
            },
            componentloader: false,
            role_selected: [],
            perm_list: [],
            select_all: false,
            btn_loading: false,
            access_record: [],
            overlay: false,
            absolute: true,
            loading: false,

            start: 0,
            timeout: null,
            rowHeight: 24,
            perPage: 25,
            focuscutoff: false,
            keys: 0,
            apisave: false,
            formchanged: false,
            firstload: true
        }
    },

    components: {create, add, alertbox, 'text-editor': TextEditor, alertdelete, VueTimepicker},

    async created(){
        this.$vuetify.goTo(0);
        this.initVal();
        await this.getSystemFull();
        await this.getOperatorFull();
        await this.getCarParkFull();
        this.firstload = false;

        let self = this;
        window.onbeforeunload = await function() {
            if (self.apisave != true){
                if (self.formchanged)
                return true;
            }
        }

        if (JSON.parse(JSON.stringify(this.$store.getters.getItems)).length > 0){
            this.form.days = JSON.parse(JSON.stringify(this.$store.getters.getItems));
            this.formchanged = true;
        }
    },

    async beforeRouteLeave (to, from , next) {
        //await this.$store.dispatch("addtempitems", {items: this.form.days});
        //let a = this.checkTableRate(this.form.days, 'some');
        //this.form.days = [];
        if (this.apisave != true){
            if (this.formchanged){
                const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
                if (answer) {
                    this.form.days = [];
                    await this.$store.dispatch("addtempitems", {items: []});
                    next()
                } else {
                    await this.$store.dispatch("addtempitems", {items: this.form.days});
                    return false;
                }
            }

            else {
                await this.$store.dispatch("addtempitems", {items: []});
                next();
            }
        }

        else{
            await this.$store.dispatch("addtempitems", {items: []});
            next();
        }
    },

    computed:{
        validateCutoff(){
            let classes = '';
            if (this.focuscutoff || this.form.cutofftime.item){
                classes += 'primary--text v-label--active';
            }

            if (this.form.cutofftime.item == '' && this.form.cutofftime.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateCutoffMain(){
            let classes = '';
            if (this.focuscutoff){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.form.cutofftime.item == '' && this.form.cutofftime.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },
    },

    methods: {
        async getSystemFull(){
            let list = [];
            this.form.system.items = [];
            try{
                this.form.system.loader = true;
                this.form.system.disable = true;
                let response = await this.$store.dispatch("getAllSystem2", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.form.system.items.push({name: "Select", SystemID: ""});
                    this.form.system.itemsFull.push({name: "Select", SystemID: ""});
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].SystemID} - ${dt[i].SystemName}`;
                        this.form.system.items.push({name: dt[i].name, SystemID: dt[i].SystemID});
                        this.form.system.itemsFull.push({name: dt[i].name, SystemID: dt[i].SystemID});
                    }
                }

                //this.system.itemsFull = dt;

                this.form.system.selected = 2;

                this.form.system.loader = false;
                this.form.system.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getOperatorFull(){
            let list = [];
            this.form.operator.items = [];
            this.form.operator.selected = "";

            try{
                this.form.operator.loader = true;
                this.form.operator.disable = true;

                let self = this;
                if (this.form.system.selected && !this.firstload){
                    let operator = this.form.operator.itemsFull.filter(item => {
                    return item.SystemID == self.form.system.selected;
                    });

                    this.form.operator.items.push({name: "Select", OperatorID: ""});

                    for(var i=0; i<operator.length; i++){
                        operator[i].name = `${operator[i].OperatorID} - ${operator[i].OperatorName}`;
                        this.form.operator.items.push({name: operator[i].name, OperatorID: operator[i].OperatorID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("listOperator", {search: ''});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.form.operator.items.push({name: "Select", OperatorID: ""});
                        this.form.operator.itemsFull.push({name: "Select", OperatorID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                            this.form.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                            this.form.operator.itemsFull.push(dt[i]);
                        }
                    }

                    //this.form.operator.selected = this.operator.items[0].OperatorID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.form.operator.loader = false;
                this.form.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.form.carpark.items = [];
            this.form.carpark.selected = "";

            try{
                this.form.carpark.loader = true;
                this.form.carpark.disable = true;

                let self = this;

                if (this.form.system.selected && !this.form.operator.selected && !this.firstload){
                    let carpark = this.form.carpark.itemsFull.filter(item => {
                    return item.SystemID == self.form.system.selected;
                    });

                    this.form.carpark.items.push({name: "Select", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.form.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }
                }

                else if ((!this.form.system.selected && this.form.operator.selected) || (this.form.system.selected && this.form.operator.selected) && !this.firstload){
                    let carpark = this.form.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.form.operator.selected;
                    });

                    this.form.carpark.items.push({name: "Select", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.form.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.form.carpark.items.push({name: "Select", CarParkID: ""});
                        this.form.carpark.itemsFull.push({name: "Select", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                        this.form.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                        this.form.carpark.itemsFull.push(dt[i]);
                    }
                    }
                }

                if (this.form.carpark.items.length > 0){
                    this.form.carpark.selected = this.form.carpark.items[0].CarParkID;
                }

                if (this.$route.query.carparkid){
                    this.form.carpark.selected = this.$route.query.carparkid;
                    this.$router.replace({'query': null});
                }

                //this.operator.itemsFull = dt;

                this.form.carpark.loader = false;
                this.form.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async addRateCard(){
            this.btn_loading = true;
            this.loading = true;
            let slots = this.readyToSend();
            try{
                let entrygraceinminutes = moment.duration(this.form.entrygrace.name, 'minutes');
                let entrygrace = moment.utc(entrygraceinminutes.asMilliseconds()).format("HH.mm");

                let exitgraceinminutes = moment.duration(this.form.exitgrace.name, 'minutes');
                let exitgrace = moment.utc(exitgraceinminutes.asMilliseconds()).format("HH.mm");

                let cutofftime = moment(this.form.cutofftime.item, 'hh:mm a').format("HH.mm");

                let activestatus = this.form.activestatus == true ? 1 : 0;

                this.swal.scolor = 'green';
                let req = {carparkid: this.form.carpark.selected,
                            name: this.form.ratecardname.name,
                            entrygrace: Number(entrygrace),
                            exitgrace: Number(exitgrace),
                            cutoff: Number(cutofftime),
                            activestatus: activestatus,
                            mon: slots[0],
                            tue: slots[1],
                            wed: slots[2],
                            thu: slots[3],
                            fri: slots[4],
                            sat: slots[5],
                            sun: slots[6],
                            phol: slots[7]
                };

                console.log('requestfinal', req);

                let response = await this.$store.dispatch("createRateCard", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    /*
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Rate Card Created!`;
                    */
                    this.apisave = true;
                    this.$router.push({ name: 'Car Park Rate', query: {created: true, carpark: this.form.carpark.selected}});
                }

                else if (response.data.code === 'AP002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code.code)}: Input Error. Please check if all slots for each day totals up to 24 hours`;
                }

                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code.code)}: This Rate Card is already created`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code.code)}`;
                }

                this.loading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.loading = false;
                this.btn_loading = false;
            }
        },

        goBack(){
            this.$router.push({name: 'Car Park Rate'});
        },

        async initVal(){
            //defaults
            let start_hour = 0;
            let end_hour = 23.59;
            let start_hour_disp = "00:00";
            let end_hour_disp = "23:59";
            for (let [index, item] of this.form.days.entries()){
                for (let i=1; i<=7; i++){
                    if(i == 1){
                        this.form.days[index].items.push({
                            dayslotid: i,
                            validstartdatetimedisp: "2020-12-31T16:00:00.000Z",
                            validstartdatetime: "2020-12-31 16:00:00",
                            validenddatetimedisp: "2049-12-31T16:00:00.000Z",
                            validenddatetime: "2049-12-31 16:00:00",
                            start_hour: start_hour,
                            end_hour: end_hour,
                            start_hour_disp: start_hour_disp,
                            end_hour_disp: end_hour_disp,
                            continue: 0,
                            rate: 0,
                            max: 0,
                            rate_type_sel_disp: "flat",
                            rate_type: "flat",
                        });
                    }

                    else
                        this.form.days[index].items.push({dayslotid: i});
                }
            }
        },

        async beforeCreateForm(flag){
            let check = await this.checkTableRate(this.form.days, 'all');

            this.form.rateslots.rule = [
                v => check || 'Please ensure there are rates entered for all days of the week'
            ];

            this.form.carpark.rule = [
                v => !!v || "Car Park is required"
            ];

            this.form.ratecardname.rule = [
                v => !!v || "Name is required"
            ];

            this.form.cutofftime.rule = [
                v => !!v || "Cut Off Time is required"
            ];

            this.form.entrygrace.rule = [
                v => v.length != 0 || "Entry Grace Period is required",
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.exitgrace.rule = [
                v => v.length != 0 || "Exit Grace Period is required",
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                self.ma.alertmodal = true;
                //self.ma.modalInfo = {id: 1, name: 2};
                self.ma.modal_action_type = flag;
                    //self.addRateCard();
                }
            });
        },

        viewRate(it){
            this.ma.customText = {};
            this.ma.modal_add = true;
            if (it.row2 != undefined)
            this.ma.customText = {item: it.row2, name: it.day, localid: it.localid, columnid: it.columnid};

            else{
                this.ma.customText = {name: it.day, localid: it.localid, columnid: it.columnid};
            }
            this.ma.modal_action_type = 'add';
        },

        deleteSlot(mainindex, innerindex){
            //this.form.days[mainindex].items.splice(innerindex, 1, {dayslotid: innerindex + 1});

            for(let idx=innerindex; idx<=6; idx++){
                delete this.form.days[mainindex].items[idx].validstartdatetimedisp;
                delete this.form.days[mainindex].items[idx].validenddatetimedisp;
                delete this.form.days[mainindex].items[idx].rate_type_input_disp;
                delete this.form.days[mainindex].items[idx].rate_type_sel_disp;
                delete this.form.days[mainindex].items[idx].start_hour_disp;
                delete this.form.days[mainindex].items[idx].end_hour_disp;
                delete this.form.days[mainindex].items[idx].start_hour;
                delete this.form.days[mainindex].items[idx].end_hour;
                delete this.form.days[mainindex].items[idx].validstartdatetime;
                delete this.form.days[mainindex].items[idx].validenddatetime;
                delete this.form.days[mainindex].items[idx].continue;
                delete this.form.days[mainindex].items[idx].max;
                delete this.form.days[mainindex].items[idx].rate;
                delete this.form.days[mainindex].items[idx].rate_type;
                delete this.form.days[mainindex].items[idx].subtime;

                if (this.form.days[mainindex].items[idx + 1].start_hour !== undefined){
                    this.form.days[mainindex].items[idx].validstartdatetimedisp = this.form.days[mainindex].items[idx + 1].validstartdatetimedisp;
                    this.form.days[mainindex].items[idx].validenddatetimedisp =  this.form.days[mainindex].items[idx + 1].validenddatetimedisp;
                    this.form.days[mainindex].items[idx].rate_type_input_disp = this.form.days[mainindex].items[idx + 1].rate_type_input_disp;
                    this.form.days[mainindex].items[idx].rate_type_sel_disp = this.form.days[mainindex].items[idx + 1].rate_type_sel_disp;
                    this.form.days[mainindex].items[idx].start_hour_disp = this.form.days[mainindex].items[idx + 1].start_hour_disp;
                    this.form.days[mainindex].items[idx].end_hour_disp = this.form.days[mainindex].items[idx + 1].end_hour_disp;
                    this.form.days[mainindex].items[idx].start_hour = this.form.days[mainindex].items[idx + 1].start_hour;
                    this.form.days[mainindex].items[idx].end_hour = this.form.days[mainindex].items[idx + 1].end_hour;
                    this.form.days[mainindex].items[idx].validstartdatetime = this.form.days[mainindex].items[idx + 1].validstartdatetime;
                    this.form.days[mainindex].items[idx].validenddatetime = this.form.days[mainindex].items[idx + 1].validenddatetime;
                    this.form.days[mainindex].items[idx].continue = this.form.days[mainindex].items[idx + 1].continue;
                    this.form.days[mainindex].items[idx].max = this.form.days[mainindex].items[idx + 1].max;
                    this.form.days[mainindex].items[idx].rate = this.form.days[mainindex].items[idx + 1].rate;
                    this.form.days[mainindex].items[idx].rate_type = this.form.days[mainindex].items[idx + 1].rate_type;
                    this.form.days[mainindex].items[idx].subtime = this.form.days[mainindex].items[idx + 1].subtime;

                    /*
                    delete this.form.days[mainindex].items[idx + 1].activationdatedisp;
                    delete this.form.days[mainindex].items[idx + 1].deactivationdatedisp;
                    delete this.form.days[mainindex].items[idx + 1].rate_type_input_disp;
                    delete this.form.days[mainindex].items[idx + 1].rate_type_sel_disp;
                    delete this.form.days[mainindex].items[idx + 1].start_hour_disp;
                    delete this.form.days[mainindex].items[idx + 1].end_hour_disp;
                    delete this.form.days[mainindex].items[idx + 1].start_hour;
                    delete this.form.days[mainindex].items[idx + 1].end_hour;
                    delete this.form.days[mainindex].items[idx + 1].activationdate;
                    delete this.form.days[mainindex].items[idx + 1].deactivationdate;
                    delete this.form.days[mainindex].items[idx + 1].continue;
                    delete this.form.days[mainindex].items[idx + 1].max;
                    delete this.form.days[mainindex].items[idx + 1].rate;
                    delete this.form.days[mainindex].items[idx + 1].rate_type;
                    delete this.form.days[mainindex].items[idx + 1].subtime;
                    */
                }
                this.keys++;
            }
        },

        async execFilter(type){
            if (type == 'system'){
                await this.getOperatorFull();
                await this.getCarParkFull();
            }

            if (type == 'operator'){
                await this.getCarParkFull();
            }
        },

        onClosed(value){
            if (value == false){
                this.ma.alertmodal = value;
                this.ma.modal_delete = value;
                this.ma.modal_add = value;
            }
        },

        onConfirm(value){
            this.ma.alertmodal = false;
            
            if (this.ma.modal_action_type == 'delete')
            {
                this.ma.modal_delete = false;
                //this.deleteProfile();
            }

            if (this.ma.modal_action_type == 'update')
            {
                this.ma.modal_edit = false;
                let entrygraceinminutes = moment.duration(this.form.entrygrace.name, 'minutes');
                let entrygrace = moment.utc(entrygraceinminutes.asMilliseconds()).format("HH.mm");

                let exitgraceinminutes = moment.duration(this.form.exitgrace.name, 'minutes');
                let exitgrace = moment.utc(exitgraceinminutes.asMilliseconds()).format("HH.mm");

                let cutofftime = moment(this.form.cutofftime.item, 'hh:mm a').format("HH.mm");
            }

            if (this.ma.modal_action_type == 'add')
            {
                this.ma.modal_add = false;
                if (value.flag == 'add'){
                    this.form.days[value.index].items[value.dayslotid].start_hour = value.start_hour;
                    this.form.days[value.index].items[value.dayslotid].end_hour = value.end_hour;
                    this.form.days[value.index].items[value.dayslotid].start_hour_disp = value.start_hour_disp; //display purpose
                    this.form.days[value.index].items[value.dayslotid].end_hour_disp = value.end_hour_disp; //display purpose
                    
                    this.form.days[value.index].items[value.dayslotid].rate_type_sel_disp = value.rate_type_sel_disp; //display purpose
                    this.form.days[value.index].items[value.dayslotid].rate_type_input_disp = value.rate_type_input_disp; //display purpose
                    this.form.days[value.index].items[value.dayslotid].rate_type = value.rate_type;

                    this.form.days[value.index].items[value.dayslotid].rate = value.rate;
                    this.form.days[value.index].items[value.dayslotid].max = value.max;
                    this.form.days[value.index].items[value.dayslotid].continue = value.continue;

                    this.form.days[value.index].items[value.dayslotid].validstartdatetimedisp = value.validstartdatetimedisp;
                    this.form.days[value.index].items[value.dayslotid].validenddatetimedisp = value.validenddatetimedisp;

                    this.form.days[value.index].items[value.dayslotid].validstartdatetime = value.validstartdatetime;
                    this.form.days[value.index].items[value.dayslotid].validenddatetime = value.validenddatetime;

                    if (value.subtime != 'undefined'){
                        let subtime = value.subtime;
                        this.form.days[value.index].items[value.dayslotid].subtime = subtime;
                    }

                    this.keys++;
                    this.formchanged = true;
                }

                if (value.flag == 'edit'){
                    console.log('edit', value);
                }
            }

            if (value == true && this.ma.modal_action_type == "createfinal"){
                console.log('done');
                this.ma.alertmodal = false;
                this.addRateCard();
            }
        },

        readyToSend(){
            let [...days] = this.form.days;
            let arr = [];
            arr = JSON.parse(JSON.stringify(days));
            let formatteddays = [];
            for (let [index, item] of arr.entries()){
                for (let [index2, item2] of item.items.entries()){
                    delete item2.dayslotid;
                    //delete item2.validstartdatetime; //remove later
                    //delete item2.validenddatetime; //remove later
                    delete item2.validstartdatetimedisp;
                    delete item2.validenddatetimedisp;
                    delete item2.rate_type_input_disp;
                    delete item2.rate_type_sel_disp;
                    delete item2.start_hour_disp;
                    delete item2.end_hour_disp;
                }

                let a = item.items.filter(i => i.start_hour !== undefined);
                formatteddays.push(a);
            }

            return formatteddays;
        },

        checkTableRate(it, allorsome){
            // loop through each key/value
            let counttruth = [];

            for(let [index, item] of it.entries()) {
                let truth = false;
                for (let [index2, item2] of item.items.entries()){
                    if (item2.start_hour != undefined){
                        truth = true;
                    }
                }

                counttruth.push(truth);
            }

            console.log(counttruth);
            let a;
            if (allorsome == 'all')
            a = counttruth.every(i => i == true);

            if (allorsome == 'some')
            a = counttruth.every(i => i == false);

            console.log(a);

            return a;
        },
    }
}
</script>