<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="650">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('add')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Add Rate for Slot ID
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <span class="text-body-2">Rate Card Info</span>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.day"
                        label="Day"
                        required
                        hide-details
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <vc-date-picker v-if="componentloader" v-model="form.validstartdatetime.item" popover mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                                <div
                                v-bind:class="validateStartDateMain"
                                class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                <div class="v-input__control">
                                <div class="v-input__slot white">
                                    <fieldset aria-hidden="true"><legend :style="focusstartdate || form.validstartdatetime.item ? {'width': '80px'} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                                    <div class="v-text-field__slot">
                                    <label for="input-startdate" v-bind:class="validateStartDate" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Activation Date *</label>
                                    <input
                                    id="input-startdate"
                                    @focus="focusstartdate = true"
                                    @blur="focusstartdate = false"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    @click="togglePopover({ placement: 'top-start', visibility: 'click' })"
                                    autocomplete="off"
                                    />
                                    </div>
                                </div>
                                <div class="v-text-field__details" v-if="form.validstartdatetime.item == '' && form.validstartdatetime.rule.length > 0">
                                    <v-input
                                        error
                                        readonly
                                        v-model="form.validstartdatetime.item"
                                        :rules="form.validstartdatetime.rule"
                                        height="0"
                                        class="hide-input-text"
                                        hide-details="auto"
                                    >
                                    </v-input>
                                </div>
                                </div>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    </vc-date-picker>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <vc-date-picker v-if="componentloader" v-model="form.validenddatetime.item" popover mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                                <div
                                v-bind:class="validateEndDateMain"
                                class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                <div class="v-input__control">
                                <div class="v-input__slot white">
                                    <fieldset aria-hidden="true"><legend :style="focusenddate || form.validenddatetime.item ? {'width': '90px'} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                                    <div class="v-text-field__slot">
                                    <label for="input-enddate" v-bind:class="validateEndDate" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Deactivation Date *</label>
                                    <input
                                    id="input-enddate"
                                    @focus="focusenddate = true"
                                    @blur="focusenddate = false"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    @click="togglePopover({ placement: 'top-start', visibility: 'click' })"
                                    autocomplete="off"
                                    />
                                    </div>
                                </div>
                                <div class="v-text-field__details" v-if="form.validenddatetime.item == '' && form.validenddatetime.rule.length > 0">
                                    <v-input
                                        error
                                        readonly
                                        v-model="form.validenddatetime.item"
                                        :rules="form.validenddatetime.rule"
                                        height="0"
                                        class="hide-input-text"
                                        hide-details="auto"
                                    >
                                    </v-input>
                                </div>
                                </div>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    </vc-date-picker>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <div v-bind:class="validateStartHourMain"
                        class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                        <div class="v-input__control">
                            <div class="v-input__slot white">
                                <fieldset aria-hidden="true"><legend :style="focusstarthour || form.start_hour.item ? {'width': '110px'} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                                <div class="v-text-field__slot">
                                <label for="input-starthour" v-bind:class="validateStartHour" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Starting Hour (HH:MM) *</label>
                                <vue-timepicker v-if="componentloader" placeholder=" " style="padding: none" id="input-starthour" input-class="vtimepicker" @focus="focusstarthour = true"
                                                @blur="focusstarthour = false" v-model="form.start_hour.item" format="HH:mm" manual-input>
                                    <template v-slot:clearButton>
                                        <v-icon x-small>fa fa-times</v-icon>
                                    </template>
                                </vue-timepicker>
                                </div>
                            </div>
                            <div class="v-text-field__details" v-if="form.start_hour.item == '' && form.start_hour.rule.length > 0">
                                <v-input
                                    error
                                    readonly
                                    v-model="form.start_hour.item"
                                    :rules="form.start_hour.rule"
                                    height="0"
                                    class="hide-input-text"
                                    hide-details="auto"
                                >
                                </v-input>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <div v-bind:class="validateEndHourMain"
                        class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                        <div class="v-input__control">
                            <div class="v-input__slot white">
                                <fieldset aria-hidden="true"><legend :style="focusendhour || form.end_hour.item ? {'width': '110px'} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                                <div class="v-text-field__slot">
                                <label for="input-endhour" v-bind:class="validateEndHour" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Ending Hour (HH:MM) *</label>
                                <vue-timepicker v-if="componentloader" placeholder=" " style="padding: none" id="input-endhour" input-class="vtimepicker" @focus="focusendhour = true"
                                                @blur="focusendhour = false" v-model="form.end_hour.item" format="HH:mm" manual-input>
                                    <template v-slot:clearButton>
                                        <v-icon x-small>fa fa-times</v-icon>
                                    </template>
                                </vue-timepicker>
                                </div>
                            </div>
                            <div class="v-text-field__details" v-if="form.end_hour.item == '' && form.end_hour.rule.length > 0">
                                <v-input
                                    error
                                    readonly
                                    v-model="form.end_hour.item"
                                    :rules="form.end_hour.rule"
                                    height="0"
                                    class="hide-input-text"
                                    hide-details="auto"
                                >
                                </v-input>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="5">
                    <v-autocomplete background-color="white"
                        dense
                        v-model="form.rate_type.selected"
                        @change="isDisableSubTime()"
                        :items="form.rate_type.items"
                        :rules="form.rate_type.rule"
                        item-text="name"
                        item-value="id"
                        label="Rate Type *"
                        hide-details="auto"
                        outlined
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="7">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.rate_type_sub.item"
                        :rules="form.rate_type_sub.rule"
                        label="Subsequent Time Slot (minutes)"
                        required
                        :disabled="disableSubtime"
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.rate.item"
                        :rules="form.rate.rule"
                        label="Rate (RM) *"
                        required
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.max.item"
                        :rules="form.max.rule"
                        label="Maximum Rate (RM)"
                        required
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <span class="text-body-2">Common Rate Policy</span>
                    <v-switch
                    color="success"
                    class="col-1 pl-0"
                    v-model="form.continue"
                    hide-details="off"
                    :label="`${form.continue == true ? 'Enabled' : 'Disabled'}`"
                    ></v-switch>
                    <span class="text-caption">{{form.continue ? "Fees is charged based on entry rate" : "Fees is charged based on each session"}}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                    <p class="text-body-2">Start Hour Time Slot</p>
                    <v-btn-toggle tile>
                    <v-btn tile class="white--text" color="primary" small @click.prevent="addRow('add')">Add Slot</v-btn>
                    <!-- <v-btn class="white--text" color="error" small @click.prevent="addRow('remove')">-</v-btn> -->
                    </v-btn-toggle>
                </v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="form.subtime"
                        hide-default-footer
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        disable-sort
                        hide-default-header
                        no-data-text="No Start Hour Time Slot. If required, click Add Slot to add row"
                    >
                        <template slot="item" slot-scope="props">
                            <tr>
                                <td>
                                    <v-text-field
                                        dense
                                        type="text"
                                        v-model.number="props.item.period.item"
                                        :rules="props.item.period.rule"
                                        label="Period (minutes)"
                                        required
                                        hide-details="auto"
                                        autocomplete="off"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                        dense
                                        type="text"
                                        v-model.number="props.item.duration.item"
                                        :rules="props.item.duration.rule"
                                        label="Duration (minutes)"
                                        required
                                        hide-details="auto"
                                        autocomplete="off"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                        dense
                                        type="text"
                                        v-model.number="props.item.rate.item"
                                        :rules="props.item.rate.rule"
                                        label="Rate (RM)"
                                        required
                                        hide-details="auto"
                                        autocomplete="off"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                        <span>
                                            <a v-on="on" id="custom-hover" @click.prevent="removeRow(props.index)"><v-icon color="red" x-small>fa fa-trash</v-icon></a>
                                        </span>
                                        </template>
                                        <span>Remove</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <!--
            <v-row v-for="(item, index) in form.subtime" :key="index">
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model.number="item.period"
                        label="Period"
                        required
                        hide-details="auto"
                        autocomplete="off"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model.number="item.duration"
                        label="Duration"
                        required
                        hide-details="auto"
                        autocomplete="off"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model.number="item.rate"
                        label="Rate (RM)"
                        required
                        hide-details="auto"
                        autocomplete="off"
                    ></v-text-field>
                </v-col>
            </v-row>
            -->
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import * as moment from "moment";

export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: {
                dayslotid: '',
                validstartdatetime: {item: '', rule: []},
                validenddatetime: {item: '', rule: []},
                start_hour: {item: '', rule: []},
                end_hour: {item: '', rule: []},
                rate_type: {items: [
                    {id: '', name: 'Select'},
                    {id: 'flat', name: 'Flat Rate'},
                    {id: 'hour', name: 'Hourly Rate'}
                    ],
                    selected: '', rule: []
                },
                rate_type_sub: {item: '', rule: []},
                rate: {item: '', rule: []},
                max: {item: '', rule: []},
                continue: false,
                subtime: [
                    {
                        period: {item: '', rule: []},
                        duration: {item: '', rule: []},
                        rate: {item: '', rule: []},
                    }
                ]
            },

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            headers: [
                { text: 'Num', value: '1', width: '15%' },
                { text: 'Period', value: 'r1', width: '12%' },
                { text: 'Duration', value: 'r2', width: '12%' },
                { text: 'Rate', value: 'r3', width: '12%' },
                { text: 'Action', value: 'r4', width: '12%' },
            ],
            focusstarthour: false,
            focusendhour: false,
            focusstartdate: false,
            focusenddate: false,
            componentloader: false,
            disableSubtime: false,
        }
    },

    components: {alertbox, VueTimepicker},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    created(){
        
    },

    computed: {
        validateStartHour(){
            let classes = '';
            if (this.focusstarthour || this.form.start_hour.item){
                classes += 'primary--text v-label--active';
            }

            if (this.form.start_hour.item == '' && this.form.start_hour.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateStartHourMain(){
            let classes = '';
            if (this.focusstarthour){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.form.start_hour.item == '' && this.form.start_hour.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },

        validateEndHour(){
            let classes = '';
            if (this.focusendhour || this.form.end_hour.item){
                classes += 'primary--text v-label--active';
            }

            if (this.form.end_hour.item == '' && this.form.end_hour.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateEndHourMain(){
            let classes = '';
            if (this.focusendhour){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.form.end_hour.item == '' && this.form.end_hour.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },

        //

        validateStartDate(){
            let classes = '';
            if (this.focusstartdate || this.form.validstartdatetime.item){
                classes += 'primary--text v-label--active';
            }

            if (this.form.validstartdatetime.item == '' && this.form.validstartdatetime.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateStartDateMain(){
            let classes = '';
            if (this.focusstartdate){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.form.validstartdatetime.item == '' && this.form.validstartdatetime.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },

        validateEndDate(){
            let classes = '';
            if (this.focusenddate || this.form.validenddatetime.item){
                classes += 'primary--text v-label--active';
            }

            if (this.form.validenddatetime.item == '' && this.form.validenddatetime.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateEndDateMain(){
            let classes = '';
            if (this.focusenddate){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.form.validenddatetime.item == '' && this.form.validenddatetime.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        }
    },

    methods: {
        addRow(typ){
            let rq = {
                period: {item: '', rule: []},
                duration: {item: '', rule: []},
                rate: {item: '', rule: []},
            };

            if (typ == 'add')
            this.form.subtime.push(rq);

            if (typ == 'remove' && this.form.subtime.length > 1)
            this.form.subtime.pop();
        },

        clearRule(){
            this.form.start_hour.rule = []; this.form.end_hour.rule = []; this.form.rate_type.rule = [];
            this.form.rate.rule = []; this.form.max.rule = []; this.form.rate_type_sub.rule = [];
            this.form.validstartdatetime.rule = []; this.form.validenddatetime.rule = [];
        },

        async beforeCreateForm(flag){
            this.clearRule();

            this.form.rate_type.rule = [
                v => !!v || 'Please select Rate Type'
            ];

            this.form.rate.rule = [
                v => v.length != 0 || 'Rate is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            if (this.form.max.item !== ''){
                this.form.max.rule = [
                    v => !Number.isNaN(Number(v)) || 'Only integer is allowed',
                    //v => (Number(this.form.max.item) > Number(this.form.rate.item)) || 'Maximum Rate should be higher than Rate'
                ];
            }

            if (this.form.rate_type.selected == "hour"){
                this.form.rate_type_sub.rule = [
                    v => !!v || 'Subsequent Time Slot is required',
                    v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
                ];
            }

            this.form.start_hour.rule = [
                v => !!v || 'Start Hour is required'
            ];

            this.form.end_hour.rule = [
                v => !!v || 'End Hour is required'
            ];

            this.form.validstartdatetime.rule = [
                v => !!v || 'Activation Date is required',
                v => moment(moment(this.form.validstartdatetime.item).format('YYYY-MM-DD')).isBefore(moment(this.form.validenddatetime.item).format('YYYY-MM-DD')) && this.form.validenddatetime.item != '' || 'Date should be before Deactivation Date'
            ];

            this.form.validenddatetime.rule = [
                v => !!v || 'Deactivation Date is required',
                v => !moment(moment(this.form.validenddatetime.item).format('YYYY-MM-DD')).isBefore(moment(this.form.validstartdatetime.item).format('YYYY-MM-DD')) || 'Date should be after Activation Date',
                v => this.form.validstartdatetime.item != '' || 'There should be an Activation Date'
            ];

            for (var i=0; i<this.form.subtime.length; i++){
                this.form.subtime[i].period.rule = [
                    v => v.length != 0 || 'Period is required',
                    v => !v || !isNaN(v) || 'Only number is allowed'
                ];

                this.form.subtime[i].duration.rule = [
                    v => v.length != 0 || 'Duration is required',
                    v => !v || !isNaN(v) || 'Only number is allowed'
                ];

                this.form.subtime[i].rate.rule = [
                    v => v.length != 0 || 'Rate is required',
                    v => !v || !isNaN(v) || 'Only number is allowed'
                ];
            }

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
                this.componentloader = false;
                this.clearRule();
            }
        },

        async handleValue(){
            this.form.subtime = [];
            this.form.dayslotid = '';
            this.form.start_hour.item = '';
            this.form.end_hour.item = '';
            this.form.validstartdatetime.item = '';
            this.form.validenddatetime.item = '';
            this.form.rate_type.selected = '';
            this.form.rate.item = '';
            this.form.max.item = '';
            this.form.continue = false;
            this.form.day = '';
            this.focusstarthour = false;
            this.focusendhour = false;
            this.form.rate_type_sub.item = '';

            this.form.day = this.customText.name;

            if (this.customText.item != undefined){
                this.form.dayslotid = this.customText.item.dayslotid;
                this.form.start_hour.item = this.customText.item.start_hour_disp;
                this.form.end_hour.item = this.customText.item.end_hour_disp;
                this.form.validstartdatetime.item = this.customText.item.validstartdatetimedisp;
                this.form.validenddatetime.item = this.customText.item.validenddatetimedisp;
                this.form.rate_type.selected = this.customText.item.rate_type_sel_disp != "flat" ? "hour" : "flat";
                this.form.rate_type_sub.item = this.customText.item.rate_type_sel_disp != "flat" ? this.customText.item.rate_type_input_disp : "";
                this.form.rate.item = this.customText.item.rate;
                this.form.max.item = this.customText.item.max == 0 ? "" : this.customText.item.max;
                this.form.continue = this.customText.item.continue == false ? false : true;
                if (this.customText.item.subtime !== undefined){
                    this.form.subtime = [];
                    for(let [index, item] of JSON.parse(JSON.stringify(this.customText.item.subtime)).entries()){

                        let period = Number(item.period).toFixed(2).toString().split('.');
                        let periodhour = period[0] == '0' ? '' : `${period[0]} `;
                        let periodminutes = period[1] == undefined ? '' : `${period[1]}`;
                        let periodfullminutes = Number(periodhour) * 60 + Number(periodminutes);

                        let duration = Number(item.duration).toFixed(2).toString().split('.');
                        let durationhour = duration[0] == '0' ? '' : `${duration[0]} `;
                        let durationminutes = duration[1] == undefined ? '' : `${duration[1]}`;
                        let durationfullminutes = Number(durationhour) * 60 + Number(durationminutes);

                        
                        let rq = {
                            period: {item: periodfullminutes, rule: []},
                            duration: {item: durationfullminutes, rule: []},
                            rate: {item: item.rate, rule: []},
                        }
                        this.form.subtime.push(rq);
                    }
                }

                else{
                    this.form.subtime = [];
                }
            }

            this.componentloader = true;
            this.isDisableSubTime();
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          //console.log(value);
                if (this.ma.modal_action_type == 'add'){
                    /*
                    "dayslotid": 1,
                    "start_hour": 0,
                    "end_hour": 20.59,
                    "rate_type": "time_1",
                    "rate": 1,
                    "max": 100,
                    "continue": "false"
                    */
                    let start_hour = moment(this.form.start_hour.item, 'HH:mm').format("HH.mm");
                    let end_hour = moment(this.form.end_hour.item, 'HH:mm').format("HH.mm");
                    let set_continue = this.form.continue == true ? 1 : 0;

                    let rate_type = "";

                    if (this.form.rate_type.selected == 'hour'){
                        let rate_type_subinminutes = moment.duration(this.form.rate_type_sub.item, 'minutes');
                        let rate_type_sub = moment.utc(rate_type_subinminutes.asMilliseconds()).format("H.mm");
                        rate_type = `time_${rate_type_sub}`;
                    }

                    else{
                        rate_type = "flat";
                    }

                    let subtime = [];
                    
                    if (this.form.subtime.length > 0){
                        for(let [index, item] of this.form.subtime.entries()){
                            let periodinminutes = moment.duration(item.period.item, 'minutes');
                            let period = moment.utc(periodinminutes.asMilliseconds()).format("H.mm");

                            let durationinminutes = moment.duration(item.duration.item, 'minutes');
                            let duration = moment.utc(durationinminutes.asMilliseconds()).format("H.mm");

                            let rq = {
                                period: period,
                                duration: duration,
                                rate: item.rate.item,
                            }
                            subtime.push(rq);
                        }
                    }

                    let max = this.form.max.item;

                    let req = {
                        dayslotid: this.customText.columnid - 1,
                        start_hour: Number(start_hour),
                        start_hour_disp: this.form.start_hour.item,
                        end_hour: Number(end_hour),
                        end_hour_disp: this.form.end_hour.item,
                        rate_type: rate_type,
                        rate_type_input_disp: this.form.rate_type_sub.item,
                        rate_type_sel_disp: this.form.rate_type.selected,
                        rate: Number(this.form.rate.item),
                        max: Number(max),
                        continue: set_continue,
                        subtime: subtime,
                        day: this.form.day,
                        index: this.customText.localid,
                        validstartdatetime: moment(new Date(this.form.validstartdatetime.item).setHours(0, 0, 0, 0)).utc().format('YYYY-MM-DD HH:mm:ss'),
                        validenddatetime: moment(new Date(this.form.validenddatetime.item).setHours(0, 0, 0, 0)).utc().format('YYYY-MM-DD HH:mm:ss'),
                        validstartdatetimedisp: this.form.validstartdatetime.item,
                        validenddatetimedisp: this.form.validenddatetime.item
                    };

                    if (subtime.length == 0) delete req.subtime;

                    req.flag = 'add';
                    this.$emit('confirm', req);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode != 9)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        removeRow(index){
            this.form.subtime.splice(index, 1);
        },

        async isDisableSubTime(){
            this.disableSubtime = false;
            if (this.form.rate_type.selected == 'hour'){
                this.form.rate_type_sub.rule = [];
                this.disableSubtime = false;
            }

            else{
                this.form.rate_type_sub.rule = [];
                this.form.rate_type_sub.item = '';
                this.disableSubtime = true;
            }
        }
    }
}
</script>